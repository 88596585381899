
import { defineComponent } from 'vue';
import { Switch }          from '@headlessui/vue';
import { switchTypes }     from '@/types';
import IconWarning         from '@/components/icons/icon-warning.component.vue';
import IconCogwheel        from '@/components/icons/icon-cogwheel.component.vue';

export default defineComponent({
  name: 'CCustomSwitch',
  components: {
    IconWarning,
    // eslint-disable-next-line vue/no-reserved-component-names
    Switch,
    IconCogwheel,
  },
  props: {
    modelValue: {
      type: Boolean,
    },
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: switchTypes.DEFAULT,
    },
    leftLabel: {
      type: String,
      default: '',
    },
    rightLabel: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return { switchTypes };
  },
});
