import { Module }    from 'vuex';
import { State }     from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { RootState } from '@/store/store.types';
import { mutations } from '@/views/administration/views/administration-issues-types/store/administration-issues-types.mutations';
import { actions }   from '@/views/administration/views/administration-issues-types/store/administration-issues-types.actions';
import { state }     from '@/views/administration/views/administration-issues-types/store/administration-issues-types.state';
import { getters }   from '@/views/administration/views/administration-issues-types/store/administration-issues-types.getters';

const store: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default store;
