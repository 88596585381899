
import { defineComponent, PropType } from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';

export default defineComponent({
  name: 'CFormErrorMessage',
  props: {
    error: {
      type: String,
      default: null,
    },
    dynamicErrorParameters: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup() {
    return {
      useGlobalTranslations,
    };
  },
});
