import { State }          from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { columnTypes }    from '@/components/common/table/table.types';
import { SidePanelState } from '@/types';

export const state: State = {
  sidePanelState: SidePanelState.EDIT_ENTITY,
  title: 'title',
  allIssuesTypes: [],
  selectedIssueType: null,
  issueTypesToReplace: [],
  tableData: [],
  controllers: [],
  tableParams: {
    groups: [],
    mainType: '',
    searchQuery: '',
  },
  columns: [
    {
      name: 'name',
      type: columnTypes.TEXT,
    },
    {
      name: 'groups',
      type: columnTypes.TAGS,
    },
    {
      name: 'controller',
      type: columnTypes.TEXT,
    },
    {
      name: 'mainType',
      type: columnTypes.TAGS,
    },
  ],
  groups: [],
};
