import { ActionTree } from 'vuex';
import {
  IssueType,
  State,
} from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { RootState } from '@/store/store.types';
import {
  createEntity,
  deleteSingleEntity, getEntitiesToReplace,
  getSingleEntity,
  getTableData, replaceEntity,
  updateSingleEntity,
} from '@/views/administration/administration.service';
import { Notification } from '@/components/notification/notification';
import i18n from '@/i18n';
import { GLOBAL }                             from '@/helpers/variables';
import { AdministrationParams, SidePanelState } from '@/types';
import {
  AdministrationEndpoints,
  AdministrationPagination, DeleteEntityResponse,
}                                             from '@/views/administration/administration.types';

export const actions: ActionTree<State, RootState> = {
  async getIssuesTypes({ commit, state, rootState }): Promise<void> {
    try {
      const { pageSize, currentPage } = rootState.pagination[
        AdministrationPagination.ISSUES_TYPES
      ];

      const params: Partial<AdministrationParams> = state.tableParams;

      const allParams: Partial<AdministrationParams> = {
        ...params,
        page: currentPage,
        size: pageSize,
      };

      const { data } = await getTableData(
        AdministrationEndpoints.ISSUES_TYPES,
        allParams
      );
      commit('setAllIssuesTypes', data.content);
      commit(
        'updatePaginationElements',
        {
          totalElements: data.totalElements,
          name: AdministrationPagination.ISSUES_TYPES,
        },
        { root: true }
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async getGroups({ commit }): Promise<void> {
    try {
      const { data } = await getTableData(AdministrationEndpoints.GROUPS, {});
      commit('setGroupsData', data.content);
    } catch (e) {
      throw e.data;
    }
  },
  async createNewIssueType(context, payload: IssueType): Promise<void> {
    try {
      await createEntity(AdministrationEndpoints.ISSUES_TYPES, payload);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t(`${GLOBAL}.success.success`)
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async updateIssueType(context, payload: IssueType): Promise<void> {
    try {
      await updateSingleEntity(
        AdministrationEndpoints.ISSUES_TYPES,
        payload.uuid as string,
        payload
      );

      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t(`${GLOBAL}.success.success`)
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async getIssueType({ commit }, issueUuid: string): Promise<void> {
    try {
      const { data } = await getSingleEntity(
        AdministrationEndpoints.ISSUES_TYPES,
        issueUuid
      );
      commit('setCurrentItem', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async removeIssueType({ dispatch }, issueUuid: string): Promise<void> {
    try {
      await deleteSingleEntity(AdministrationEndpoints.ISSUES_TYPES, issueUuid);
    } catch (e) {
      e.data.errorCode === DeleteEntityResponse.DELETE_RESTRICT
        ? dispatch('getIssueTypesToReplace', issueUuid)
        : Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${GLOBAL}.errors.${e.data}`)
        );

      throw e.data;
    }
  },

  async getIssueTypesToReplace({ commit }, issueUuid: string) {
    try {
      const { data } = await getEntitiesToReplace(AdministrationEndpoints.ISSUES_TYPES, issueUuid)
      commit('setIssueTypesToReplace', data)
      commit('setSidePanelState', SidePanelState.DELETE_ENTITY)
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async replaceIssueType(context, { issueTypeUuid, replaceToIssueTypeUuid }: {issueTypeUuid: string, replaceToIssueTypeUuid: string}) {
    try {
      await replaceEntity(AdministrationEndpoints.ISSUES_TYPES, issueTypeUuid, replaceToIssueTypeUuid)
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('issuesTypes.notifications.replace')
      );
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getUsers({ commit }): Promise<void> {
    try {
      const { data } = await getTableData(AdministrationEndpoints.USERS, { page: 1, size: 999 });
      commit('setControllers', data.content);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
};
