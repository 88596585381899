import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-6 flex flex-column"
}
const _hoisted_2 = { class: "text-10 pl-f3 text-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.error)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("span", _hoisted_2, _toDisplayString(_ctx.useGlobalTranslations(`errors.${_ctx.error}`, [_ctx.dynamicErrorParameters])), 1)
      ]))
    : _createCommentVNode("", true)
}