import { MutationTree } from 'vuex';
import {
  IssueType,
  State,
}                                                          from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { AdministrationParams, FilterTypes, SidePanelState } from '@/types';
import { columnTypes }                                     from '@/components/common/table/table.types';

export const mutations: MutationTree<State> = {
  setAllIssuesTypes(state, payload: IssueType[]): void {
    state.allIssuesTypes = payload;
  },
  setGroupsData(state, groups: []): void {
    state.columns = [
      {
        name: 'name',
        type: columnTypes.TEXT,
        filter: FilterTypes.SORT,
      },
      {
        name: 'groups',
        type: columnTypes.TAGS,
        filter: FilterTypes.MULTI_SELECT,
        filterOptions: groups,
        selectedOptions: []
      },
      {
        name: 'controller',
        type: columnTypes.TEXT,
        filter: FilterTypes.SORT,
      },
      {
        name: 'mainType',
        type: columnTypes.TAGS,
        filter: FilterTypes.SINGLE_SELECT,
        filterOptions: [
          {
            name: 'MAINTENANCE',
            color: '#DB002CFF',
          },
          {
            name: 'CARE',
            color: '#1E306E',
          },
        ],
        selectedOptions: [],
      },
    ];
    state.groups = groups;
  },
  setCurrentItem(state, issueType: IssueType): void {
    const controller = state.controllers.find(
      (controller) => controller.uuid === issueType.controller.uuid
    );

    const mapped = {
      uuid: controller!.uuid,
      label: `${controller!.name} ${controller!.lastName}`,
      email: controller!.email,
    };

    state.selectedIssueType = issueType;
  },
  clearSelected(state): void {
    state.selectedIssueType = null;
  },
  setTableParams(state, params: AdministrationParams): void {
    state.tableParams = params;
  },
  setControllers(state, data: any): void {
    state.controllers = data;
  },
  setIssueTypesToReplace(state, payload: IssueType[]) {
    state.issueTypesToReplace = payload;

  },
  setSidePanelState(state, payload: SidePanelState) {
    state.sidePanelState = payload
  }
};
