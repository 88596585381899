
import { computed, ComputedRef, defineComponent, PropType, toRef } from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import cFormErrorMessage from '@/components/common/form/form-error-message/form-error-message.component.vue';
import IconArrowDown from '@/components/icons/icon-arrow-down.component.vue';
import { useSelect } from '@/hooks/use-select';
import { SelectItem } from '@/types';
import IconCheck from '@/components/icons/icon-check.component.vue';

export default defineComponent({
  name: 'CFormSelectNew',
  components: {
    IconCheck,
    IconArrowDown,
    cFormErrorMessage,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    labelProp: {
      type: String,
      default: 'name',
    },
    secondLabelProp: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      default: () => [],
    }
  },
  emits: ['selected', 'update:modelValue'],

  setup(props, { emit }) {
    const {
      isFocused,
      setIsFocus,
      handleFocusOut,
      container,
      input,
      searchValue,
      liElements,
      getListItemRefs,
      handleKeyDownEvent,
      selectValue,
      filteredItems
    } = useSelect(toRef(props, 'items'), (item) => {
      const payload = item.value === props.modelValue ? '' : item.value
      emit('update:modelValue', payload)
      emit('selected', payload)
    })

    const selectedItem: ComputedRef<SelectItem | undefined> = computed(() => props.items.find(({ value }) => value === props.modelValue))

    return {
      isFocused,
      setIsFocus,
      filteredItems,
      handleFocusOut,
      selectValue,
      useGlobalTranslations,
      handleKeyDownEvent,
      searchValue,
      getListItemRefs,
      liElements,
      container,
      input,
      selectedItem,
    };
  },
});
