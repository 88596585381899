import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-14 pt-f5 leading-8 text-gray-600" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_error_icon = _resolveComponent("c-form-error-icon")
  const _component_c_radio_button = _resolveComponent("c-radio-button")

  return (_openBlock(), _createBlock("div", {
    class: "flex flex-col",
    title: _ctx.error ? _ctx.useGlobalTranslations(`errors.${_ctx.error}`) : ''
  }, [
    _createVNode("span", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createBlock("span", _hoisted_2, "*"))
        : _createCommentVNode("", true),
      _createVNode(_component_c_form_error_icon, { error: _ctx.error }, null, 8, ["error"])
    ]),
    _createVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.group, (tag, index) => {
          return (_openBlock(), _createBlock(_component_c_radio_button, {
            key: index,
            class: "cursor-pointer focus:outline-none focus:ring-2",
            color: tag.color,
            "first-element": index === 0,
            "model-value": _ctx.modelValue,
            name: _ctx.label,
            "tag-name": tag.name,
            value: tag.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$emit('update:modelValue', $event))))
          }, null, 8, ["color", "first-element", "model-value", "name", "tag-name", "value"]))
        }), 128))
      ])
    ])
  ], 8, ["title"]))
}