import { SelectItem } from '@/types';
import { computed, ComputedRef, ref, Ref } from 'vue';

enum KeyEvent {
    ARROW_DOWN = 'ArrowDown',
    ARROW_UP = 'ArrowUp'
}

interface UseSelect {
    container: Ref<HTMLDivElement | null>
    input: Ref<HTMLInputElement | null>
    liElements: Ref<HTMLElement[]>
    isFocused: Ref<boolean>
    searchValue: Ref<string>
    filteredItems: ComputedRef<SelectItem[]>
    selectValue: (item: SelectItem) => void
    getListItemRefs: (el: HTMLElement, index: number) => void,
    setIsFocus: (state: boolean) => void
    handleKeyDownEvent: ($event: KeyboardEvent, index: number) => void
    handleFocusOut: (e: FocusEvent) => void
}


export const useSelect = (items: Ref<SelectItem[]>, fn: (item: SelectItem) => void, isMultiSelect?: boolean): UseSelect => {
  const container: Ref<HTMLDivElement | null> = ref(null);
  const input: Ref<HTMLInputElement | null> = ref(null);
  const liElements: Ref<HTMLElement[]> = ref([])
  const isFocused: Ref<boolean> = ref<boolean>(false);
  const searchValue: Ref<string> = ref('');


  const selectValue = (item: SelectItem): void => {
    fn(item)
    if (isMultiSelect) return
    searchValue.value = ''
    setIsFocus(false);
  };


  const filteredItems: ComputedRef<SelectItem[]> = computed(() => {
    if (!searchValue.value) {
      return items.value
    }

    return items.value.filter(item => item.name
      .toLowerCase()
      .includes(searchValue.value.toLowerCase())
    )
  })


  const getListItemRefs = (el: HTMLElement, index: number): void => {
    liElements.value[index] = el;
  };

  const handleKeyDownEvent = ($event: KeyboardEvent, index: number) => {
    switch ($event.key) {
      case KeyEvent.ARROW_DOWN:
        handleArrowDownActions(index);
        break;
      case KeyEvent.ARROW_UP:
        handleArrowUpActions(index)
        break;
    }

  }

  const setFocusOnListElement = (index: number) => {
    liElements.value[index].focus()
  }

  const setIsFocus = (state: boolean): void => {
    isFocused.value = state;
  };

  const handleFocusOut = (e: FocusEvent) => {
    const isOutside = e.relatedTarget
            && !(container.value as HTMLElement).contains(e.relatedTarget as HTMLElement)
            || !e.relatedTarget

    isOutside && setIsFocus(false)

  }


  const handleArrowDownActions = (index: number): void => {
    let highlight = index;
    const lastListElementIndex = filteredItems.value.length - 1
    if (highlight === -1) {
      setFocusOnListElement(0)
      return
    }


    if (highlight === lastListElementIndex) {
      setFocusToInput()
      return
    }
    highlight++
    setFocusOnListElement(highlight)
  }

  const handleArrowUpActions = (index: number): void => {
    const lastListElementIndex = filteredItems.value.length - 1
    let highlight = index;
    if (highlight === -1) {
      liElements.value && liElements.value[lastListElementIndex].focus()
      return
    }

    if (highlight === 0) {
      setFocusToInput()
      return
    }

    highlight--
    setFocusOnListElement(highlight)
    return
  }

  const setFocusToInput = (): void => {
    input.value && input.value.focus();
  }

  return {
    container,
    input,
    liElements,
    isFocused,
    searchValue,
    filteredItems,
    getListItemRefs,
    handleKeyDownEvent,
    setIsFocus,
    handleFocusOut,
    selectValue
  }
}
