import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "inline-flex items-center "
}
const _hoisted_2 = {
  key: 1,
  class: "inline-flex items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type === _ctx.switchTypes.DOUBLE)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("span", {
          class: _ctx.modelValue ? 'font-bold text-14' : 'font-light text-14 text-gray-400'
        }, _toDisplayString(_ctx.leftLabel), 3),
        _createVNode("switch", {
          class: "relative inline-flex bg-primary-200 items-center justify-between h-5 rounded-full w-12 m-4 focus:outline-none cursor-pointer",
          value: _ctx.modelValue,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue)))
        }, [
          _createVNode("span", {
            class: ["inline-block w-7 h-7 transform duration-200 rounded-full bg-primary-600", _ctx.modelValue ? '-translate-x-1' : 'translate-x-5']
          }, null, 2)
        ], 8, ["value"]),
        _createVNode("span", {
          class: !_ctx.modelValue ? 'font-bold text-14' : 'font-light text-14 text-gray-400'
        }, _toDisplayString(_ctx.rightLabel), 3)
      ]))
    : (_ctx.type === _ctx.switchTypes.SINGLE && !_ctx.icon)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("span", {
            class: _ctx.modelValue ? 'font-bold' : 'font-light text-gray-400'
          }, _toDisplayString(_ctx.leftLabel), 3),
          _createVNode("switch", {
            class: ["relative inline-flex items-center h-5 w-12 rounded-full m-4 focus:outline-none cursor-pointer", _ctx.modelValue ? 'bg-primary-200' : 'bg-gray-200'],
            value: _ctx.modelValue,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue)))
          }, [
            _createVNode("span", {
              class: ["inline-block w-7 h-7 transform duration-200 rounded-full", _ctx.modelValue ? '-translate-x-1 bg-primary-600': 'translate-x-5 bg-gray-600']
            }, null, 2)
          ], 10, ["value"])
        ]))
      : (_ctx.icon)
        ? (_openBlock(), _createBlock("div", {
            key: 2,
            class: "inline-flex items-center",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue)))
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
              class: ["w-6 h-6 xs:w-8 xs:h-8", _ctx.modelValue ? 'text-primary-600' : 'font-light text-gray-600']
            }, null, 8, ["class"])),
            _createVNode("span", {
              class: ["text-right w-10 xs:w-24 ml-3 sm:ml-5", _ctx.modelValue ? 'font-bold text-base xs:text-14' : 'font-light text-base xs:text-14 text-gray-400']
            }, _toDisplayString(_ctx.leftLabel), 3),
            _createVNode("switch", {
              class: ["relative inline-flex items-center h-3 w-10 rounded-full xs:h-5 sm:w-12 m-4 focus:outline-none cursor-pointer", _ctx.modelValue ? 'bg-primary-200' : 'bg-gray-200'],
              value: _ctx.modelValue
            }, [
              _createVNode("span", {
                class: ["inline-block w-5 h-5 xs:w-7 xs:h-7 transform duration-200 rounded-full", _ctx.modelValue ? '-translate-x-1 bg-primary-600' : 'translate-x-5 bg-gray-600']
              }, null, 2)
            ], 10, ["value"]),
            _createVNode("span", {
              class: !_ctx.modelValue ? 'font-bold text-base xs:text-14' : 'font-light text-base xs:text-14 text-gray-400'
            }, _toDisplayString(_ctx.rightLabel), 3)
          ]))
        : (_openBlock(), _createBlock("switch", {
            key: 3,
            class: ["relative inline-flex items-center h-5 rounded-full w-12 m-4 focus:outline-none cursor-pointer", _ctx.modelValue ? 'bg-primary-200' : 'bg-gray-200'],
            value: _ctx.modelValue,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue)))
          }, [
            _createVNode("span", {
              class: ["inline-block w-7 h-7 transform duration-200 rounded-full", _ctx.modelValue ? '-translate-x-1 bg-primary-600' : 'translate-x-5 bg-gray-600']
            }, null, 2)
          ], 10, ["value"]))
}