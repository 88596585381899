
import { computed, ComputedRef, defineComponent, PropType, reactive, Ref, ref } from 'vue';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useGlobals } from '@/hooks/use-globals';
import { useTranslations } from '@/hooks/use-translations';
import cCustomSwitch from '@/components/common/custom-switch/custom-switch.component.vue';
import CFormRadioGroup from '@/components/common/form/form-radio-group/form-radio-group.component.vue';
import cFormInput from '@/components/common/form/form-input/form-input.component.vue';
import {
  IssueFormRules,
  IssueTypeFormField, State
} from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import CAdministrationFormButtons
  from '@/views/administration/components/administration-form-buttons/administration-form-buttons.component.vue';
import { FormMode } from '@/views/administration/administration.types';
import { MainType, RadioGroup, SelectItem } from '@/types';
import { Notification } from '@/components/notification/notification';
import i18n from '@/i18n';
import { GLOBAL } from '@/helpers/variables';
import { typeGuards } from '@/views/administration/views/administration-issues-types/helpers/type-guards';
import CFormSelectNew from '@/components/common/form/form-select/form-select.component-new.vue';

export default defineComponent({
  name: 'CAdministrationIssuesTypesForm',
  components: {
    CFormSelectNew,
    CAdministrationFormButtons,
    CFormRadioGroup,
    cCustomSwitch,
    cFormInput,
  },
  props: {
    formMode: {
      type: String as PropType<FormMode>,
      default: FormMode.ADD,
    },
  },
  emits: ['close-panel'],
  setup(props, context) {
    const { store } = useGlobals();
    const localState: State = store.state.views['issuesTypes'];
    const model: ComputedRef<IssueTypeFormField> = computed(() => store.getters['views/issuesTypes/selectedIssueType']);
    const controllers: ComputedRef<SelectItem[]> = computed(() => store.getters['views/issuesTypes/getControllers']);
    const groups: ComputedRef<RadioGroup[]> = computed(() => localState.groups.map(({
      uuid,
      ...group
    }) => ({
      value: uuid,
      ...group
    })));

    const switchValue: Ref<boolean> = ref(props.formMode === FormMode.EDIT ? model.value.mainType === MainType.CARE : false);

    const state = reactive(model.value);


    const rules: ComputedRef<IssueFormRules> = computed(() => {
      return {
        name: { required },
        group: { required },
        controller: { required },
      };
    });

    const v$ = useVuelidate((rules as unknown) as ValidationArgs, state as any);


    const handleSubmit = async (closePanel: boolean): Promise<void> => {
      v$.value.$touch();
      if (!v$.value.$error) {
        try {
          const editIssueType = {
            name: state.name,
            groupUuid: typeGuards(model.value.group),
            mainType: switchValue.value ? MainType.CARE : MainType.MAINTENANCE,
            controller: localState.controllers.find(({ uuid }) => uuid === state.controller)?.email,
            uuid: state.uuid,
          };
          switch (props.formMode) {
            case FormMode.ADD:
              await store.dispatch('views/issuesTypes/createNewIssueType', editIssueType);
              await store.dispatch('views/issuesTypes/getIssuesTypes');
              break;
            case FormMode.EDIT:
              await store.dispatch('views/issuesTypes/updateIssueType', editIssueType);
              await store.dispatch('views/issuesTypes/getIssuesTypes');
              break;
            default:
              break;
          }
          resetForm();
          if (closePanel) {
            context.emit('close-panel');
          }
        } catch (e) {
          throw Error(e);
        }
      } else {
        Notification.warning(
          i18n.global.t(`${GLOBAL}.warning.warning`),
          i18n.global.t(`${GLOBAL}.warning.validation`),
        );
      }
    };

    const resetForm = (): void => {
      state.name = '';
      state.group = '';
      state.controller = '';
      state.mainType = '';
      state.uuid = '';
      v$.value.$reset();
    };

    const removeIssueType = async (): Promise<void> => {
      await store.dispatch('views/issuesTypes/removeIssueType', model.value.uuid);
      await store.dispatch('views/issuesTypes/getIssuesTypes');
      context.emit('close-panel');
    };

    return {
      v$,
      state,
      switchValue,
      groups,
      controllers,
      handleSubmit,
      removeIssueType,
      useTranslations,
    };
  },
});
