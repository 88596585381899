import { GetterTree } from 'vuex';
import { RootState } from '@/store/store.types';
import {
  IssueType,
  IssueTypeFormField,
  State,
} from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { TableRow } from '@/components/common/table/table.types';
import { MainType, SearchbarSelectItem } from '@/types';

export const getters: GetterTree<State, RootState> = {
  selectedIssueType(state): IssueTypeFormField {
    if (state.selectedIssueType) {
      return ({
        ...state.selectedIssueType,
        controller: state.selectedIssueType.controller.uuid,
        group: state.selectedIssueType.group.uuid
      })
    }
    return {
      name: '',
      group: '',
      controller: '',
      mainType: '',
    };
  },
  tableData(state): TableRow[] {
    const tableData = state.allIssuesTypes.map((issue: IssueType) => ({
      name: issue.name,
      groups: [issue.group],
      mainType: [{
        name: issue.mainType,
        color: issue.mainType === MainType.MAINTENANCE ? '#DB002CFF' : '#1E306E',
      }],
      controller: `${issue.controller.name} ${issue.controller.lastName}`,
      uuid: issue.uuid as string,
    }));

    return tableData;
  },
  issueTypesToReplace(state): SearchbarSelectItem[] {
    return state.issueTypesToReplace.map(issueType => ({ label: issueType.name, uuid: issueType.uuid }))
  },
  getControllers(state) {
    return state.controllers.map(({ uuid, name, lastName }) => ({ value: uuid, name: `${name} ${lastName}` }))
  }
};
