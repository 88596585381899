
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue';
import { useGlobals }                             from '@/hooks/use-globals';
import { useGlobalTranslations, useTranslations } from '@/hooks/use-translations';
import cSidePanel                               from '@/components/common/side-panel/side-panel.component.vue';
import { TableColumn, TableRow }                                   from '@/components/common/table/table.types';
import { AdministrationParams, SearchbarSelectItem, SidePanelState } from '@/types';
import { AdministrationPagination, FormMode }                      from '@/views/administration/administration.types';
import CAdministrationIssuesTypesForm
  from '@/views/administration/views/administration-issues-types/components/administration-issues-types-form/administration-issues-types-form.component.vue';
import cAdministrationTemplate   from '@/views/administration/components/administration-template/administration-template.component.vue';
import { IssueType, State }        from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { OptionDropdownElement } from '@/components/application-header/use-application-header';
import CDeleteConfirmation                      from '@/views/administration/components/delete-confirmation/delete-confirmation.component.vue';
import { Group }                                  from '@/views/administration/views/administration-groups/administration-groups.types';
import { Issue }                                  from '@/views/issues/issues.types';

export default defineComponent({
  name: 'VAdministrationIssuesTypes',
  components: {
    CDeleteConfirmation,
    CAdministrationIssuesTypesForm,
    cAdministrationTemplate,
    cSidePanel,

  },
  setup() {
    const { store, i18n } = useGlobals();
    const localState: State = store.state.views[ 'issuesTypes' ];
    const columns: ComputedRef<TableColumn[]> = computed(() => localState.columns);
    const tableData: ComputedRef<TableRow[]> = computed(() => store.getters[ 'views/issuesTypes/tableData' ]);
    const params: ComputedRef<Partial<AdministrationParams>> = computed(() => localState.tableParams);
    const issueTypesToReplace: ComputedRef<SearchbarSelectItem[]> = computed(() => store.getters['views/issuesTypes/issueTypesToReplace']);
    const sidePanelState: ComputedRef<SidePanelState> = computed(() => localState.sidePanelState);
    const selectedIssueType: ComputedRef<IssueType | null> = computed(() => localState.selectedIssueType);
    const sidePanelTitle: Ref<string> = ref('');
    const isSidePanelOpen: Ref<boolean> = ref(false);
    const formMode: Ref<FormMode> = ref(FormMode.ADD);

    store.commit('views/issuesTypes/setTableParams', []);
    store.dispatch('views/issuesTypes/getGroups');
    store.dispatch('views/issuesTypes/getUsers');
    store.dispatch('views/issuesTypes/getIssuesTypes');

    const closeSidePanel = (): void => {
      isSidePanelOpen.value = false;
      store.commit('views/issuesTypes/setSidePanelState', SidePanelState.EDIT_ENTITY);
      store.commit('views/issuesTypes/clearSelected');
    };

    const openAddNewIssueType = (): void => {
      sidePanelTitle.value = i18n.t('sidePanel.addNewIssueType');
      isSidePanelOpen.value = true;
      formMode.value = FormMode.ADD;
    };

    const changeIssueType = async (issueUuid: string): Promise<void> => {
      closeSidePanel();
      await store.dispatch('views/issuesTypes/getIssueType', issueUuid);
      formMode.value = FormMode.EDIT;
      sidePanelTitle.value = i18n.t('sidePanel.editIssueType');
      isSidePanelOpen.value = true;
    };

    const updateParams = (updatedParams: Partial<AdministrationParams>): void => {
      store.commit('views/issuesTypes/setTableParams', updatedParams);
      store.dispatch('views/issuesTypes/getIssuesTypes', updatedParams);
    };


    const goBackToEditForm = () => {
      store.commit('views/issuesTypes/setSidePanelState', SidePanelState.EDIT_ENTITY)
    }

    const replaceIssueTypeAndDelete = async (issueTypeUuidToReplace: string) => {
      selectedIssueType.value && await store.dispatch('views/issuesTypes/replaceIssueType', { issueTypeUuid: selectedIssueType.value.uuid, replaceToIssueTypeUuid: issueTypeUuidToReplace })
      store.dispatch('views/issuesTypes/getIssuesTypes')
      closeSidePanel()
    }

    return {
      useGlobalTranslations,
      useTranslations,
      formMode,
      sidePanelTitle,
      columns,
      params,
      isSidePanelOpen,
      tableData,
      AdministrationPagination,
      openAddNewIssueType,
      updateParams,
      closeSidePanel,
      changeIssueType,
      issueTypesToReplace,
      sidePanelState,
      SidePanelState,
      replaceIssueTypeAndDelete,
      goBackToEditForm
    };
  },
})
;
