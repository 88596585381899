import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "mt-f13 text-14 pt-f5 leading-8 text-gray-600" }
const _hoisted_3 = { class: "flex flex-col h-full justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_input = _resolveComponent("c-form-input")
  const _component_c_form_radio_group = _resolveComponent("c-form-radio-group")
  const _component_c_form_select_new = _resolveComponent("c-form-select-new")
  const _component_c_custom_switch = _resolveComponent("c-custom-switch")
  const _component_c_administration_form_buttons = _resolveComponent("c-administration-form-buttons")

  return (_openBlock(), _createBlock("div", {
    class: "flex h-full flex-col px-f21 pt-f21 outline-none",
    tabindex: "0",
    onKeypress: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.handleSubmit(true)), ["enter"]))
  }, [
    _createVNode(_component_c_form_input, {
      modelValue: _ctx.state.name,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.name = $event)),
      error: _ctx.v$.name.$error ? 'required' : null,
      label: _ctx.useTranslations('sidePanel.form.name'),
      placeholder: _ctx.useTranslations('sidePanel.form.name'),
      required: !!_ctx.v$.name.required,
      type: "text"
    }, null, 8, ["modelValue", "error", "label", "placeholder", "required"]),
    _createVNode(_component_c_form_radio_group, {
      modelValue: _ctx.state.group,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.group = $event)),
      class: "ml-f3",
      error: _ctx.v$.group.$error ? 'required' : null,
      group: _ctx.groups,
      label: _ctx.useTranslations('sidePanel.form.group'),
      required: !!_ctx.v$.group.required
    }, null, 8, ["modelValue", "error", "group", "label", "required"]),
    _createVNode(_component_c_form_select_new, {
      "model-value": _ctx.state.controller,
      "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.state.controller = $event)),
      error: _ctx.v$.controller.$error ? 'required' : null,
      "input-id": "contro",
      items: _ctx.controllers,
      label: _ctx.useTranslations('sidePanel.form.controller'),
      placeholder: _ctx.useTranslations('sidePanel.form.controller'),
      required: !!_ctx.v$.controller.required
    }, null, 8, ["model-value", "error", "items", "label", "placeholder", "required"]),
    _createVNode("div", _hoisted_1, [
      _createVNode("span", _hoisted_2, _toDisplayString(_ctx.useTranslations('sidePanel.form.switch.label')), 1),
      _createVNode(_component_c_custom_switch, {
        modelValue: _ctx.switchValue,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.switchValue = $event)),
        "left-label": _ctx.useTranslations('sidePanel.form.switch.left-side'),
        "right-label": _ctx.useTranslations('sidePanel.form.switch.right-side'),
        type: "double"
      }, null, 8, ["modelValue", "left-label", "right-label"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_c_administration_form_buttons, {
        class: "flex justify-between mt-f34",
        "form-mode": _ctx.formMode,
        name: "IssueType",
        onAddNext: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleSubmit(false))),
        onCancel: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close-panel'))),
        onRemove: _ctx.removeIssueType,
        onSave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleSubmit(true))),
        onSubmit: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"]))
      }, null, 8, ["form-mode", "onRemove"])
    ])
  ], 32))
}