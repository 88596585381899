
import { defineComponent, computed } from 'vue';
import CTag from '@/components/common/tag/tag.component.vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import { useGlobals } from '@/hooks/use-globals';
import { translate } from '@intlify/core-base';

export default defineComponent({
  name: 'CRadioButton',
  components: { CTag },
  props: {
    name: {
      type: String,
      required: true,
    },
    tagName: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#06D6A0',
    },
    firstElement: {
      type: Boolean,
      default: false,
    },
    translate: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],
  setup() {
    const { router } = useGlobals();
    const notTranslate = computed(() => {
      return (
        router.currentRoute.value.path.includes('administration') ||
        router.currentRoute.value.path.includes('profile')
      );
    });
    return {
      useGlobalTranslations,
      notTranslate,
    };
  },
});
