
import { defineComponent, PropType } from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import cFormErrorIcon from '@/components/common/form/form-error-icon/form-error-icon.component.vue';
import CRadioButton from '@/components/common/form/form-radio-button/form-radio-button.component.vue';
import { RadioGroup } from '@/types';


export default defineComponent({
  name: 'CFormRadioGroup',
  components: { CRadioButton, cFormErrorIcon },
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    group: {
      required: true,
      type: Array as PropType<RadioGroup[]>,
    },
    label: {
      required: true,
      type: String,
    },
    error: {
      required: false,
      default: '',
      type: String,
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:modelValue'],
  setup() {

    return {
      useGlobalTranslations,
    };
  },
});
